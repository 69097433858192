table {
	border: 1px solid lightgray;
}

tbody {
	border-bottom: 1px solid lightgray;
}

th {
	border-bottom: 1px solid lightgray;
	border-right: 1px solid lightgray;
	padding: 2px 4px;
	text-align: center;
}

td{
	// border-right: 1px solid lightgray;
	padding: 5px;
}

tfoot {
	color: gray;
}

tfoot th {
	font-weight: normal;
}
