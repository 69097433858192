
.rdt_Table{
    .rdt_TableHead {
        .rdt_TableHeadRow{
            border-bottom-width: 3px !important;
            border-bottom-color: #BE7BFF !important;
        }
        .rdt_TableCol{
            .rdt_TableCol_Sortable{
                justify-content: center !important;
                font-weight: bold !important;
            }
        }
    }
    .rdt_TableCell{
        justify-content: center !important;
        color: #474747 !important;
        font-weight: 500 !important;
    }
}

.card-body{
    .sc-dmRaPn{
        &.iuJhzw{
            /* cambiar estilo de scroll */
            &::-webkit-scrollbar {
                width: 10px !important;
                background: #9c92a4 !important;
                border-radius: 5px !important;
            }
            &::-webkit-scrollbar-thumb {
                background: #842eff !important;
                border-radius: 5px !important;
            }
        }
    }
}



/* Custom input with icon */
.custom-input-container{
    border: 1px solid #ccc;
    // padding: 5px;
    border-radius: 5px;
    color: #333 !important;
    background: #faf3ff !important;
    border: 1px solid #eadcff  !important;
    .custom-input{
        border: none !important;
        background: transparent !important;
        &:focus{
            outline: none !important;
        }
        &::placeholder{
            color: #ccc !important;
        }
    }
    
    .btn-clear{
        background: transparent !important;
        border: none !important;
        color: #842eff !important;
    }
    .btn-search{
        border-radius: 0 5px 5px 0 !important;
        background: transparent !important;
        border: none !important;
        border-left: 1px solid #ccc !important;
        background: #842eff!important;
        color: #fff !important;
        &:hover{
            background: #600ffc !important;
        }
    }
}


.rdt_Table .rdt_TableHead .rdt_TableCol .rdt_TableCol_Sortable{
    color: #842eff !important;
}


.jVeafb:not(:last-of-type){
    border-bottom-color: #eee1ff !important;
}


/* Custom cell colums */

.column-details{
    border-radius: 5px !important;
    padding: 2px !important;
    color: #992AFF !important;
    &:hover{
        cursor: pointer !important;
        background: #992AFF !important;
        color: #fff !important;
    }
}