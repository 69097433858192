

/* Form Add Docente */
.form-add-docente{
    .img-profile-container{
        position: relative;
        .img-profile{
            width: 150px;
            height: 150px;
            border-radius: 10px;
            object-fit: cover;
            object-position: center;
        }
        .button-container{
            align-items: center;
            background-color: #fff;
            border-radius: 5px;
            bottom: 5px;
            display: flex;
            width: 30px;
            height: 30px;
            cursor: pointer;
            position: absolute;
            justify-content: center;
            .img-profile-button{
                width: 100%;
                height: 100%;
                color: #842eff;
                &:hover{
                    color: #29d698;
                }
            }
        }
    }
}



/* Dashboard Top 5 Docentes */
.card-top-docente{
    border-radius: 5px;
    border: 2px solid #D6A8FB !important;
    padding: 30px;
    //aplicar sombra a card
    box-shadow: 0px 0px 10px rgba(0,0,0,0.1);
    
}



