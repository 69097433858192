

/* Card Docente Courses */

.card-docente-courses{
    .docente-avatar{
        max-width: 80px !important;
        border-radius: 50% !important;
    }
    
    .list-courses{
        border-radius: 5px;
        border: 1px solid #E9CFFF !important;
        background: #FAF3FF !important;
        padding: 10px;
        overflow-y: scroll !important;
        max-height: 200px;
        min-height: 200px;
        
        /* cambiar estilo de scroll */
        &::-webkit-scrollbar {
            width: 10px !important;
            background: #9c92a4 !important;
            border-radius: 5px !important;
        }
        &::-webkit-scrollbar-thumb {
            background: #842eff !important;
            border-radius: 5px !important;
        }
        
        
        .course-item{
            padding: 5px;
            padding-bottom: 10px;
            border-bottom: 2px solid #E9CFFF !important;
            &-icon{
                min-width: 25px !important;
                max-width: 25px !important;
            }
            span{
                word-break: break-all;
            }
        }
    }
}



/* Card Docente Performance */

.avatar-docente{
    img{
        max-width: 80px !important;
        border-radius: 50% !important;
    }
    span{
        color: #000 !important;
        font-weight: 400 !important;
    }
}



/* Card Docente Comments and Blog*/
.header-details{
    border-bottom: 2px solid #E9CFFF !important;
    padding-bottom: 10px;
}


.list-items-details{
    padding: 10px;
    overflow-y: scroll !important;
    max-height: 300px;
    
    /* cambiar estilo de scroll */
    &::-webkit-scrollbar {
        width: 10px !important;
        background: #9c92a4 !important;
        border-radius: 5px !important;
    }
    &::-webkit-scrollbar-thumb {
        background: #842eff !important;
        border-radius: 5px !important;
    }
    
    .item-detail{
        border-bottom: 1px solid #E9CFFF !important;
        padding-bottom: 10px;
    }
}