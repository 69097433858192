

.login-box{
    align-items: center;
    background-color: #e9ecef;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    justify-content: center;
}


.login-header{
    p{
        font-size: 1.5rem;
        font-weight: bold;
        margin-bottom: 0;
    }
}

