.img-portada-container{
    position: relative;
    max-width: 340px;
    margin:auto;
    .img-profile{
        max-width: 340px;
        border-radius: 10px;
        object-fit: cover;
        object-position: center;
        border-color: #842eff;
        border-width: 2px;
        border-style: solid;
    }
    .button-container{
        align-items: center;
        background-color: #842eff;
        border-radius: 5px;
        bottom: 5px;
        display: flex;
        width: 30px;
        height: 30px;
        cursor: pointer;
        position: absolute;
        justify-content: center;
        bottom:-5px;
        right: -5px;
        .img-profile-button{
            width: 100%;
            height: 100%;
            color: #fff;
            &:hover{
                color: #29d698;
            }
        }
    }
}

.comment-item img{
    max-width: 100%;
    border-radius: 5px;
}

.comment-item-date{
    color: #29d698;
    margin-left: 8px;
}

.comment-item-action{
    justify-content: center;
    text-align: center;
    align-items: center;
    align-self: flex-start;
    color: #29d698;
}

