.card-header-live {
  color: white;
  background-color: rgb(153, 42, 255);
  padding: 1rem 2rem;
  border-radius: 1rem 1rem 0 0;
}

.crear-live-card-body {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 2rem 4rem;
  .crear-live-row {
    margin-bottom: 1rem;
    p {
      font-size: 1.4rem;
      margin-bottom: 0;
    }
    textarea {
      width: 100%;
      border: 1px solid rgb(153, 42, 255);
      border-radius: 0.25rem;
      padding: 8px 16px;
    }
    input {
      width: 100%;
      border: 1px solid rgb(153, 42, 255);
      border-radius: 0.25rem;
      padding: 8px 16px;
    }
  }
  .crear-live-submit {
    color: white;
    background-color: rgb(153, 42, 255);
    padding: 0.8rem 2rem;
    border: none;
    border-radius: 0.5rem;
    height: 100%;
    margin-bottom: 1rem;
  }
}

.crear-live-color-picker div {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  border: 1px solid #992aff;
  border-radius: 0.25rem;
  height: 2.3rem;
}

.crear-live-color-picker div span {
  color: #2f3543;
}

.crear-live-color-picker input {
  border: none !important;
  background-color: transparent;
  width: 2rem !important;
  height: 2rem;
}

.crear-live-row-last {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  gap: 1rem;
  input {
    height: 3rem;
  }
}

.card-live {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 0 solid rgba(0, 0, 0, 0.125);
  border-radius: 1rem;
}

.crear-live-option {
  display: flex;
  gap: 2rem;
  margin-bottom: 2rem;
  button {
    color: white;
    background-color: rgb(153, 42, 255);
    padding: 0.6rem 1.5rem;
    border: none;
    border-radius: 0.25rem;
  }
}

.crear-live-upload{
  position: relative;
  max-height: 400px;
  width: 100%;
  .btn-ref-file{
    position: absolute;
    top: 0;
    left: 0;
  }
}

.crear-live-upload img {
  aspect-ratio: 5/12;
  height: 100%;
  max-height: 400px;
}

@media (max-width:480px) {
  .crear-live-card-body{
    padding: 1rem 2rem;
  }
}