



.img-form-preview{
    position: relative;
    border-radius: 10px;
    border: 3px solid #BE7BFF;
    height: 100% !important;
    min-height: 150px !important;
    .btn-ref-file{
        cursor: pointer;
        position: absolute;
        bottom: -8px;
        right: -8px;
        padding: 5px;
        background: #842eff;
        border-radius: 10px;
        color: #fff;
        &:hover{
            background: #842eff;
        }
    }
}



.ge-form{
    border: 1px solid #842eff;
    &:focus{
        border: 2px solid #842eff;
    }
}

input[type="checkbox"]{
    &:checked{
        accent-color: #842eff !important;
    }
}

/* Editor text rich */
.ge-editor {
    border: 2px solid #842eff;
    border-radius: 10px;
    // height: 100%;
    .rdw-editor-toolbar{
        padding: 10px !important;
        border: none;
        border-bottom: 2px solid #842eff;
        border-radius: 10px 10px 0 0;
    }
    
    .ge-editor-input{
        border: none;
        border-radius: 10px;
        padding: 10px;
        height: 100%;
    }
}

.react-datepicker-popper{
    z-index: 2 !important;
}


/* Form-control file */
input[type=file].form-control{
    padding: 0;
    height: 100%;
    &::-webkit-file-upload-button{
        cursor: pointer;
        background: #842eff !important;
        border: none !important;
        color: #fff;
        padding: 5px;
        border-radius: 0.25rem;
        margin-right: 15px;
        &:focus{
            background: #1a0d2c;
        }
    }
}

.card-ingresos-gastos { 
    background-color: #fff;
    box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 2px 0px, rgba(14, 30, 37, 0.32) 0px 2px 12px 0px;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    overflow: hidden;
    padding: 15px;
}

.card-nps {
    background-color: #fff;
    box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 2px 0px, rgba(14, 30, 37, 0.32) 0px 2px 12px 0px;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 15px;
    overflow: hidden;
}