.carousel-btn-container {
	color: #842eff;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 2rem;
	margin-top: 1rem;
}

.carousel-btn-container p {
	font-size: 1.5rem;
}

.carousel-btn-container .arrow-icons {
	margin: 0 10px;
}

.carousel-btn-container .arrow-icons:hover {
	cursor: pointer;
	font-size: 2.2rem;
}
