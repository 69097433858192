.cuadro-pagos-docentes {
    background: linear-gradient(111deg,#7d34f4,#6610f2) repeat-x!important;
    border-radius: 3px;
    box-shadow: #7d34f4 0px 5px 10px;
    width: 25px;
    height: 25px;
}

.container-pagos-docente {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-right: solid 2.3px #c510f270;
}

.container-pagos-docente:nth-child(1) {
    align-items: flex-start;
}

.container-pagos-docente:nth-child(3) {
    border: none;
}

.porcentaje-pagos {
    display: flex;
    justify-content: start;
    align-items: center;
    width: 90px;
    border-radius: 5px;
}

.porcentaje-pagos span:nth-child(1) {
    background: #7d34f4;
    display: flex;
    justify-content: center;
    padding-left: 8px;
    padding-right: 6px;
    color: #fff;
    font-size: 22px;
    border-top: solid 2.5px #7e34f4;
    border-left: solid 2.5px #7d34f4;
    border-bottom: solid 2.5px #7d34f4;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    height: 100%;
}

.porcentaje-pagos span:nth-child(2) {
    font-weight: bold;
    display: flex;
    justify-content: start;
    padding-left: 10px;
    width: 70px;
    font-size: 22px;
    border-top: solid 2.5px #b210f2b6;
    border-right: solid 2.5px #b210f2b6;
    border-bottom: solid 2.5px #b210f2b6;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

.select-cursos-pagos {
    display: block;
    width: 100%;
    height: calc(2.25rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #6610f2;
    border-radius: 0.25rem;
    box-shadow: rgb(155, 153, 153) 0px 2px 10px;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.select-cursos-pagos:focus {outline: none;}

.pago-uno-docente {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 140px;
    margin-right: 20px;
    border: 1px solid #6610f2;
    box-shadow: rgb(155, 153, 153) 0px 2px 10px;
    border-radius: 0.25rem;
}

.pago-uno-docente span:nth-child(2) {
    padding: 0.375rem 0.75rem;
}

.pago-uno-docente span:nth-child(1) {
    border-right: solid 1px gray;
    padding: 0.375rem 0.35rem;
}
