
.ge-txt-primary{
    color: #812cff !important;
}

.ge-txt-secondary{
    color: #BF86FF !important;
}

.txt-bold{
    font-weight: bold !important;
}

.txt-italic{
    font-style: italic !important;
}

.txt-semi-dark{
    color: #c0c0c1 !important;
}

.txt-white{
    color: #fff !important;
}

.txt-small{
    font-size: 0.8em !important;
}

.txt-dark{
    color: #000 !important;
}

.txt-ge-muted{
    color: #ccc !important;
    font-size: .8rem !important;
}

.ge-txt-muted-dark{
    color: rgb(136, 135, 135) !important;
    font-size: .8rem !important;
}

.ge-h1{
    font-size: 2.5rem !important;
    font-weight: bold !important;
}

.ge-h2{
    font-size: 2rem !important;
    font-weight: bold !important;
    &{
        @media screen and (max-width: 255px) {
            font-size: 1.5rem !important;
        }
    }
}

.ge-h3{
    font-size: 1.5rem !important;
    font-weight: bold !important;
    &{
        @media screen and (max-width: 255px) {
            font-size: 1.2rem !important;
        }
    }
}

.ge-h4{
    font-size: 1.2rem !important;
    font-weight: bold !important;
    &{
        @media screen and (max-width: 255px) {
            font-size: 1rem !important;
        }
    }
}

/* Custom weight dynamic func */
.ge-weight-bold{
    font-weight: bold !important;
}
.ge-weight-light{
    font-weight: 300 !important;
}
.ge-weight-normal{
    font-weight: normal !important;
}
.ge-weight-medium-alt{
    font-weight: 400 !important;
}
.ge-weight-medium{
    font-weight: 500 !important;
}
.ge-weight-semibold{
    font-weight: 600 !important;
}


.ge-title{
    color: #474747 !important;
}


.ge-deg-primary{
    color: #fff;
    background: linear-gradient(180deg, #812cff, #600ffc) !important;
}

.ge-bg-primary {
    background-color: #812cff !important;
}

.ge-bg-primary-light {
    background: #FCEFFF !important;
}

.ge-btn-deg-primary{
    color: #fff;
    background: linear-gradient(180deg, #812cff, #600ffc) !important;
    &:hover{
        color: #fff;
        background: linear-gradient(180deg, #600ffc, #812cff) !important;
    }
}

.ge-btn-primary{
    color: #fff;
    background: #812cff !important;
    &:hover{
        color: #fff;
        background: #600ffc !important;
    }
    &.active{
        color: #fff;
        background: #982AFF !important;
    }
    &:disabled{
        background: #CB8FFF !important;
    }
}

.ge-btn-primary-alt{
    color: #fff;
    background: #9d53e2 !important;
    &:hover{
        color: #fff;
        background: #600ffc !important;
    }
    &.active{
        color: #fff;
        background: #982AFF !important;
        //border: 2px solid #3c1689 !important;
    }
    &:disabled{
        background: #CB8FFF !important;
    }
}

.ge-noborder{
    border: none !important;
}

.ge-radius{
    border-radius: 5px !important;
}

.pointer{
    cursor: pointer !important;
}

.ge-input{
    padding: 5px;
    border-radius: 5px;
    color: #333 !important;
    background: #FCEFFF !important;
    border: 1px solid #F7DCFF !important;
    &::placeholder{
        color: #ccc !important;
    }
}

.btn-desabilitado{
    background-color: #606060 !important;
}

.ge-btn-select-gasto {
    color: black;
    background: #FFF;
    border: solid 2px #600ffc;
    border-radius: 5px;
}


/* Hovers */
.ge-hover-scale{
    cursor: pointer !important;
    &:hover{
        transform: scale(1.05);
    }
}

.modulo-inactivo {
    background-color: #606060 !important;
}

.btn-editar-pregunta {
    background-color: #842eff !important;
}

.btn-eliminar-pregunta{
    background-color: #606060 !important;
}

.no-tiene-cursos-asignado{
    background-color: #606060 !important;
}

.no-tiene-escuelas-asignados{
    background-color: #606060 !important;
}

.btn-eliminar-factura{
    background-color: #606060 !important;
}

.btn-documento{
    background-color: #842eff !important;
}

.btn-eliminar-live{
    background-color: #606060 !important;
}

.btn-copiar-enlace-live{
    background-color: #29d698 !important;
}

.btn-curso-estudiante{
    &.active{
        background-color: #842eff !important;
        &:hover{
            color: #fff !important;
        }
    }
    &:hover{
        cursor: pointer;
        color: #852eff !important;
    }
}