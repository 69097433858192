

.custom-date{
    border: 1px solid #E8D1FB !important;
    background: #FAF3FF !important;
    border-radius: 5px !important;
    
    &-input{
        border: none !important;
        background: transparent !important;
        color: #9B969D !important;
        &:focus{
            outline: none !important;
            color: #A13BFF !important;
        }
    }
}