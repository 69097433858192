

/* Dropzone file items */
.dz-file-item{
    position: relative;
    width: 100%;
    margin-bottom: 10px;
    border: 1px solid #7b7b7b;
    border-radius: 5px;
    padding: 10px;
    font-size: 12px;
    cursor: default;
    color: #fff;
    animation: fadeIn 0.5s ease-in-out;
    
    
    .file-details{
        .span-error{
            //texto italic
            font-style: italic;
        }
    }
    
    &.file-success{
        background-color: #389738;
    }
    
    &.file-error{
        background-color: #b45f5f;
        .btn-delete-file{
            border: 1px solid #fff;
            &:hover{
                background-color: #fff;
                color: #b45f5f;
            }
        }
    }
    
    .btn-delete-file{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 20px;
        height: 20px;
        color: #fff;
        background: transparent;
        border-radius: 50%;
        border: 1px solid #d9534f;
        background-color: rgba(205, 96, 92, 0.524);
        padding: 5px;
        cursor: pointer;
        transition: all .3s ease-in-out;
        &:hover{
            background-color: #c9302c;
            border: none;
            color: #fff;
        }
    }
}

//animaciones
@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}