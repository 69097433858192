
/* 
    Funcion dinámica para bordes 
    ej: ge-bl-1, ge-br-1, ge-bt-1, ge-bb-1
*/
@mixin border($position, $width, $color) {
    @if $position == 'left' {
        border-left: $width solid $color;
    }
    @else if $position == 'right' {
        border-right: $width solid $color;
    }
    @else if $position == 'top' {
        border-top: $width solid $color;
    }
    @else if $position == 'bottom' {
        border-bottom: $width solid $color;
    }
}

.ge-bl-1 {
    @include border('left', 4px, #000);
}

.ge-bl-2 {
    @include border('left', 8px, #7e2fbb);
}
