


.progress-bar-docente{
    .semicircle-container{
        display: inline-block !important;
    }
    // span:not(.progress-detail){
    .semicircle-percent-value{
        font-size: 2.8rem !important;
        font-weight: bold !important;
        color: #474747 !important;
        @media screen and (max-width: 255px) {
            font-size: 2.5rem !important;
        }
    }
    .progress-detail{        
        color: #9900ef !important;
        font-size: 1rem !important;
    }
}