
.sidebar-gestora{
    background-color: #992AFF !important;
    
    .brand-link, .user-panel{
        border-bottom: 1px solid #dddae2 !important;
    }
}



.nav-sidebar>.nav-item>.nav-link {
    color: #fff !important;
    
    &.active{
        background-color: #fff !important;
        color: #842eff  !important;
        box-shadow: none !important;
    }
}


.nav.nav-treeview>.nav-item>.nav-link{
    color: #fff !important;
    font-size: .8rem !important;
    &:hover{
        background-color: rgba(255,255,255,.2) !important;
        box-shadow: none !important;
    }
    &.active{
        background-color: #fff !important;
        color: #842eff  !important;
        box-shadow: none !important;
    }
}


[class*=sidebar-dark-] .nav-sidebar>.nav-item.menu-open>.nav-link, [class*=sidebar-dark-] .nav-sidebar>.nav-item:hover>.nav-link, [class*=sidebar-dark-] .nav-sidebar>.nav-item>.nav-link:focus{
    background-color: rgba(255,255,255,.4);
}