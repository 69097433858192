


.dark-modal{
    .modal-content{
        background-color: #41A5E1;
    }
    .modal-header{
        background-color: #41A5E1;
    }
    .modal-footer{
        background-color: #41A5E1;
    }
}


/* Custom btn close modal */
.modal-header .btn-close{
    background: url("../../../public/images/times.png") no-repeat 50% !important;
    background-size: contain !important;
    padding: .5rem .5rem;
    margin: .5rem -.10rem -.5rem auto;
    border: none;
}