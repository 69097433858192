
.card-full {
    width: 100%;
}




.card-no-after{
    &:after{
        display: none !important;
    }
}

/* Fix legend and fieldset Bootstrap */
fieldset {
    display: block !important;
    margin-inline-start: 2px !important;
    margin-inline-end: 2px !important;
    padding-block-start: 0.35em !important;
    padding-inline-start: 0.75em !important;
    padding-inline-end: 0.75em !important;
    padding-block-end: 0.625em !important;
    min-inline-size: min-content !important;
    border-width: 2px !important;
    border-style: groove !important;
    border-color: #982AFF !important;
    border-image: initial !important;
    border-radius: 0.25rem !important;
}

legend {
    display: block !important;
    padding-inline-start: 2px !important;
    padding-inline-end: 2px !important;
    border-width: initial !important;
    border-style: none !important;
    border-color: initial !important;
    border-image: initial !important;
    font-size: 1rem !important;
    font-weight: 600 !important;
    color: #614C94 !important;
    width: auto !important;
}



/* .filepond--credits{
    display: none !important;
} */