.card-ingresos-docente {
    background-color: #fff;
    box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    margin-right: 20px;
}

.card-ingresos-docente:nth-child(4) {
    margin: 0;
}

.table-ingreso-docente {
    background-color: #fff;
    box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
    border-radius: 10px;
}

.title-ingresos-docente {
    color: #6610f2;
    font-size: 1.06em;
    text-align: start;
    width: 140px;
    margin: 0;
    font-weight: bold;
}

.monto-ingresos-docente {
    font-size: 50px;
    font-weight: 600;
    font-family: 'Roboto', sans-serif;
    color: rgb(0, 0, 0, 0.76);
}