.box-datos-afiliados {
    display: flex;
    justify-content: start;
    align-items: center;
    padding-left: 10px;
    border: solid 1px #da60f3;
    background: #fad7f5;
    color: rgb(109, 105, 105);
    border-radius: 3px;
    margin-left: 10px;
    width: 200px;
    overflow: hidden;
}

.container-datos {
    display: flex;
    text-align: center;
    justify-content: flex-start;
    align-items: center;
}

.container-datos-afiliados {
    display: flex;
    width: auto;
    text-align: center;
    height: 35px;
}

.container-datos-afiliados span {
    display: flex;
    align-items: center;
}

.box-redes-sociales {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px;
  color: #6d1ac5;
  border-radius: 5px;
  border: solid 1px #752ae5;
}

.text-area-afiliados {
  resize: none;
  padding: 10px;
  width: 100%;
  border-radius: 10px;
  border: solid 1px #da60f3;
  background: #fad7f5;
  height: 80px;
}